import { __awaiter, __generator } from "tslib";
import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from 'firebase/app';
import 'firebase/auth';
import store from '@/store';
import NotFoundComponent from '@/views/404.vue';
import Intro from '@/views/Intro.vue';
Vue.use(VueRouter);
export var routes = [
    {
        path: '/',
        name: 'Home',
        component: Intro,
        meta: {
            title: 'BIM Meet'
        }
    },
    {
        path: '/autodesk/callback',
        name: 'CallbackHandler',
        component: function () { return import('@/views/auth/AutodeskLoginPopup.vue'); }
    },
    {
        path: '/meet/:id',
        name: 'Meet',
        component: function () { return import('@/views/Meeting.vue'); },
        props: true,
        meta: {
            hideNavbar: true
        }
    },
    {
        path: '/user-profile',
        name: 'User Profile',
        component: function () { return import('@/views/UserProfile.vue'); },
        meta: {
            requiresAuth: true,
            hideProfileBtn: true
        }
    },
    {
        path: '/user/:tab',
        name: 'User',
        component: function () { return import('@/views/User.vue'); },
        meta: {
            requiresAuth: true,
            hideProfileBtn: true
        }
    },
    {
        path: '/register',
        component: function () { return import('@/views/auth/Register.vue'); },
        meta: {
            requiresNoAuth: true,
            hideNavbar: true
        }
    },
    {
        path: '/verify',
        component: function () { return import('@/views/auth/VerifyEmail.vue'); },
        meta: {
            hideNavbar: true
        }
    },
    {
        path: '/login',
        component: function () { return import('@/views/auth/LoginPage.vue'); },
        meta: {
            requiresNoAuth: true
        }
    },
    {
        path: '/policy',
        component: function () { return import('@/views/PolicyViewer.vue'); }
    },
    {
        path: '*',
        component: NotFoundComponent
    },
    {
        path: '/admin-panel',
        name: 'Admin',
        component: function () { return import('@/views/auth/AdminPanel.vue'); },
        meta: {
            requiresAdminAuth: true
        }
    }
];
var router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes,
    linkExactActiveClass: 'is-active',
    scrollBehavior: function (to) {
        if (to.hash) {
            return {
                selector: to.hash
            };
        }
    }
});
export var beforeEach = function (to, from, next) {
    return __awaiter(void 0, void 0, void 0, function () {
        var requiresAuth, requiresNoAuth, requiresAdminAuth, isAdmin, user;
        return __generator(this, function (_a) {
            console.log('router before each guard called!!');
            requiresAuth = to.matched.some(function (record) { return record.meta.requiresAuth; });
            requiresNoAuth = to.matched.some(function (record) { return record.meta.requiresNoAuth; });
            requiresAdminAuth = to.matched.some(function (record) { return record.meta.requiresAdminAuth; });
            isAdmin = store.getters.userRole === 'admin';
            user = firebase.auth().currentUser;
            if (requiresAdminAuth && !isAdmin)
                next('/');
            if (requiresAuth && !user) {
                next('/login');
            }
            else if (requiresNoAuth && user) {
                next('user-profile');
            }
            else {
                next();
            }
            return [2];
        });
    });
};
router.afterEach(function (to, from) {
    var HTML = document.getElementsByTagName('html')[0];
    to.fullPath.includes('/meet/') && HTML.classList.add('global-scrollbar');
    from.fullPath.includes('/meet/') && HTML.classList.remove('global-scrollbar');
});
router.beforeEach(function (to, from, next) { return beforeEach(to, from, next); });
router.onError(function (err) {
    console.warn('NAV_ERR', err);
});
export default router;
