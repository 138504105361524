import { __decorate, __extends } from "tslib";
import { Component, Mixins } from 'vue-property-decorator';
import Login from '@/components/auth/Login.vue';
import NavButtonsValidate from './MainNav/NavButtonsValidation';
import UserAvatar from './UserAvatar.vue';
var MainNav = (function (_super) {
    __extends(MainNav, _super);
    function MainNav() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MainNav.prototype.mounted = function () {
        this.$log('warning', 'user', this.$auth.currentUser);
    };
    MainNav = __decorate([
        Component({
            components: {
                Login: Login,
                UserAvatar: UserAvatar
            }
        })
    ], MainNav);
    return MainNav;
}(Mixins(NavButtonsValidate)));
export default MainNav;
