import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Hero from '@/components/bulma/Hero.vue';
var NotFoundComponent = (function (_super) {
    __extends(NotFoundComponent, _super);
    function NotFoundComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NotFoundComponent = __decorate([
        Component({
            components: { Hero: Hero }
        })
    ], NotFoundComponent);
    return NotFoundComponent;
}(Vue));
export default NotFoundComponent;
