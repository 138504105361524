import { __awaiter, __generator } from "tslib";
import { db } from '../../initFirebase';
import toast from '../../helpers/toastHelpers';
var userUpdatesActions = {
    updateCoverPicture: function (_a, _b) {
        var dispatch = _a.dispatch;
        var uid = _b.uid, coverImg = _b.coverImg;
        return __awaiter(this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 3, , 4]);
                        return [4, db.doc('users/' + uid).update({ coverImg: coverImg })];
                    case 1:
                        _c.sent();
                        return [4, dispatch('loadUserState', { uid: uid })];
                    case 2:
                        _c.sent();
                        toast.goodMessage('cover picture');
                        return [2, true];
                    case 3:
                        error_1 = _c.sent();
                        toast.badMessage('update cover picture');
                        return [2, error_1];
                    case 4: return [2];
                }
            });
        });
    }
};
export { userUpdatesActions };
