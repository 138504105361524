import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import Vue from 'vue';
export var db = firebase
    .initializeApp(JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG))
    .firestore();
export var auth = firebase.auth();
export var functions = firebase.functions();
var storage = firebase.storage();
Vue.prototype.$firestore = db;
Vue.prototype.$auth = auth;
Vue.prototype.$functions = functions;
Vue.prototype.$storage = storage;
console.log("storage", storage.ref('/users'));
if (process.env.NODE_ENV === 'development') {
    Vue.prototype.$functions.useFunctionsEmulator('http://localhost:5001');
    Vue.prototype.$firestore.settings({
        host: 'localhost:5002',
        ssl: false
    });
    firebase.auth().useEmulator('http://localhost:9099/');
}
