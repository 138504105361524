import { __decorate, __extends } from "tslib";
import MainNav from '@/components/layout/MainNav.vue';
import { Component, Vue } from 'vue-property-decorator';
import CookieLaw from 'vue-cookie-law';
import UserMenu from './components/layout/UserMenu.vue';
import { mixin as clickaway } from 'vue-clickaway2';
var App = (function (_super) {
    __extends(App, _super);
    function App() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.userMenu = false;
        return _this;
    }
    App.prototype.toggleUserMenu = function () {
        this.userMenu = !this.userMenu;
    };
    App = __decorate([
        Component({
            components: {
                MainNav: MainNav,
                CookieLaw: CookieLaw,
                UserMenu: UserMenu
            },
            mixins: [clickaway],
            watch: {
                $route: {
                    immediate: true,
                    handler: function (to) {
                        document.title = to.meta.title || 'BIM Meet';
                    }
                }
            }
        })
    ], App);
    return App;
}(Vue));
export default App;
