import { __assign, __awaiter, __generator } from "tslib";
import { functions } from '@/initFirebase';
var meetingActions = {
    updateMeeting: function (_a, meeting) {
        var commit = _a.commit, dispatch = _a.dispatch;
        return __awaiter(void 0, void 0, void 0, function () {
            var itemId, projectId;
            return __generator(this, function (_b) {
                commit('setMeeting', meeting);
                if (meeting.itemId) {
                    itemId = meeting.itemId, projectId = meeting.projectId;
                    dispatch('getParentFolder', { projectId: projectId, itemId: itemId });
                }
                return [2];
            });
        });
    },
    getParentFolder: function (_a, _b) {
        var commit = _a.commit;
        var itemId = _b.itemId, projectId = _b.projectId;
        return __awaiter(void 0, void 0, void 0, function () {
            var data, parentFolder, error_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4, functions.httpsCallable('getParentFolder')({
                                projectId: projectId,
                                itemId: itemId
                            })];
                    case 1:
                        data = (_c.sent()).data;
                        parentFolder = data.relationships.parent.data.id;
                        commit('setMeeting', { parentFolder: parentFolder });
                        return [3, 3];
                    case 2:
                        error_1 = _c.sent();
                        console.log(error_1);
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    },
    selectedModelData: function (_a, data) {
        var commit = _a.commit, dispatch = _a.dispatch;
        commit('setSelectedModelData', data);
    }
};
var meetingMutations = {
    setMeeting: function (state, meeting) { return (state.meeting = __assign(__assign({}, state.meeting), meeting)); },
    setSelectedModelData: function (state, data) {
        state.selectedModelData = data;
    }
};
export { meetingActions, meetingMutations };
