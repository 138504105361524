import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import VueApollo from 'vue-apollo';
var apiUri = process.env.VUE_APP_GRAPHQL_HTTP_URL;
var wsUri = process.env.VUE_APP_GRAPHQL_WS_URL;
var httpLink = new HttpLink({ uri: apiUri });
var wsLink = new WebSocketLink({
    uri: wsUri,
    options: {
        reconnect: true
    }
});
var link = split(function (_a) {
    var query = _a.query;
    var res = getMainDefinition(query);
    return res.kind === 'OperationDefinition' && res.operation === 'subscription';
}, wsLink, httpLink);
export var apollo = new ApolloClient({
    link: link,
    cache: new InMemoryCache({
        addTypename: false
    })
});
export default new VueApollo({
    defaultClient: apollo
});
