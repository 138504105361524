import { __awaiter, __decorate, __extends, __generator } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Hero from '@/components/bulma/Hero.vue';
import CardVideoBimMeet from '@/components/common/CardVideoBimMeet.vue';
var Intro = (function (_super) {
    __extends(Intro, _super);
    function Intro() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.meetCode = '';
        _this.creatingMeet = false;
        _this.error = null;
        _this.skeletonLoading = true;
        return _this;
    }
    Intro.prototype.mounted = function () {
        this.skeletonLoader();
    };
    Intro.prototype.createButtonTooltipLabel = function () {
        var label = '';
        if (!this.$auth.currentUser)
            label = 'You must be logged in to create a BIMMeet';
        else if (!this.$auth.currentUser.emailVerified)
            label = 'You must verify your email to create a BIM Meet';
        return label;
    };
    Intro.prototype.createNewMeet = function () {
        var _this = this;
        this.creatingMeet = true;
        var createMeetingFunc = this.$functions.httpsCallable('createMeeting');
        createMeetingFunc()
            .then(function (meetId) {
            console.log('meeting created successfully', meetId);
            _this.$router.push("/meet/" + meetId.data);
            _this.creatingMeet = false;
        })
            .catch(function (err) {
            _this.error = err;
            _this.creatingMeet = false;
        });
    };
    Intro.prototype.skeletonLoader = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, setTimeout(function () {
                            _this.skeletonLoading = false;
                        }, 1500)];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    Intro = __decorate([
        Component({
            components: { CardVideoBimMeet: CardVideoBimMeet, Hero: Hero }
        })
    ], Intro);
    return Intro;
}(Vue));
export default Intro;
