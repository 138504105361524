import { tabItems } from '@/components/user/UserPreferences/tabItems';
var userTab = {
    tabs: {
        currentItem: 'Account',
        tabItems: tabItems
    }
};
var userTabMutations = {
    setActiveTab: function (_a, tab) {
        var tabs = _a.tabs;
        return (tabs.currentItem = tab);
    }
};
export { userTab, userTabMutations };
