import { __decorate, __extends } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { adminSettings, userSettings } from './UserMenu/menuItems';
import UserAvatar from './UserAvatar.vue';
var UserMenu = (function (_super) {
    __extends(UserMenu, _super);
    function UserMenu() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.menuItems = {
            userSettings: userSettings,
            adminSettings: adminSettings
        };
        _this.vm = _this;
        _this.right = null;
        return _this;
    }
    UserMenu.prototype.goToOldAccount = function () {
        this.isNotCurrentPath('/user-profile') && this.$router.push('/user-profile');
    };
    UserMenu.prototype.goToNewAccount = function (tab) {
        console.log('TCL: UserMenu -> goToNewAccount -> tab', tab);
        this.$store.commit('setActiveTab', tab);
        this.$router.push("/user/" + tab);
    };
    UserMenu.prototype.signOut = function () {
        console.log('signing out user');
        var _a = this, $store = _a.$store, $router = _a.$router, $route = _a.$route;
        $store.commit('signOut');
        $route.path !== '/' && $router.push('/');
    };
    UserMenu.prototype.isNotCurrentPath = function (path) {
        return this.$route.path !== path;
    };
    UserMenu = __decorate([
        Component({
            components: { UserAvatar: UserAvatar }
        })
    ], UserMenu);
    return UserMenu;
}(Vue));
export default UserMenu;
