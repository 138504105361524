import { __awaiter, __decorate, __extends, __generator } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
var Login = (function (_super) {
    __extends(Login, _super);
    function Login() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.email = '';
        _this.password = '';
        _this.loading = false;
        _this.error = '';
        return _this;
    }
    Login.prototype.autodeskLogin = function () {
        return __awaiter(this, void 0, void 0, function () {
            var routeData;
            return __generator(this, function (_a) {
                routeData = this.$router.resolve({ name: 'CallbackHandler' });
                window.open(routeData.href, 'Login with Autodesk', 'height=585,width=400');
                return [2];
            });
        });
    };
    Login.prototype.signIn = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, email, password;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loading = true;
                        _a = this, email = _a.email, password = _a.password;
                        return [4, this.$store.dispatch('loginWithEmail', { email: email, password: password })];
                    case 1:
                        _b.sent();
                        this.loading = false;
                        return [2];
                }
            });
        });
    };
    Login.prototype.signOut = function () {
        this.loading = true;
        this.$store.commit('signOut');
        this.loading = false;
        this.$router.push('/');
    };
    Login.prototype.recoverPassword = function () {
        var _this = this;
        this.$auth
            .sendPasswordResetEmail(this.email)
            .then(function () { })
            .catch(function (err) {
            _this.error = err;
        });
    };
    Login = __decorate([
        Component
    ], Login);
    return Login;
}(Vue));
export default Login;
