import { __assign } from "tslib";
import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import { authActions, authMutations, user } from './modules/auth';
import { viewer, markupMutations } from './modules/viewer';
import { meetingActions, meetingMutations } from './modules/meeting';
import { modal, modalMutations } from './modules/modals';
import { twilio, twilioActions, twilioMutations } from './modules/twilio';
import { userTab, userTabMutations } from './modules/userTabs';
import { userUpdatesActions } from './modules/userUpdates';
var debug = process.env.NODE_ENV !== 'production';
var plugins = debug ? [createLogger({})] : [];
Vue.use(Vuex);
var state = __assign({ viewer: viewer,
    user: user, meeting: {
        modelURN: null
    }, selectedModelData: {
        containerId: '',
        folderId: '',
        hubId: '',
        itemId: '',
        projectId: ''
    }, error: '', modal: modal,
    twilio: twilio }, userTab);
var mutations = __assign(__assign(__assign(__assign(__assign(__assign({}, authMutations), meetingMutations), modalMutations), twilioMutations), userTabMutations), markupMutations);
var actions = __assign(__assign(__assign(__assign({}, authActions), meetingActions), twilioActions), userUpdatesActions);
export default new Vuex.Store({
    state: state,
    mutations: mutations,
    actions: actions,
    getters: {
        userId: function (_a) {
            var user = _a.user;
            return user.uid;
        },
        userNick: function (_a) {
            var user = _a.user;
            return (user === null || user === void 0 ? void 0 : user.displayName) || (user === null || user === void 0 ? void 0 : user.email) || '';
        },
        userEmail: function (_a) {
            var user = _a.user;
            return user.email || '';
        },
        isModelLoaded: function (state) { return !!state.meeting.modelURN; },
        userRole: function (_a) {
            var user = _a.user;
            return user === null || user === void 0 ? void 0 : user.role;
        }
    },
    plugins: plugins
});
