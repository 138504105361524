import { __decorate, __extends } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
var Hero = (function (_super) {
    __extends(Hero, _super);
    function Hero() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Hero.prototype, "style", {
        get: function () {
            var color = this.color ? 'is-' + this.color : 'is-primary';
            var size = this.nav ? 'is-fullheight-with-navbar' : 'is-fullheight';
            return color + ' ' + size;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop(String)
    ], Hero.prototype, "color", void 0);
    __decorate([
        Prop()
    ], Hero.prototype, "nav", void 0);
    Hero = __decorate([
        Component
    ], Hero);
    return Hero;
}(Vue));
export default Hero;
