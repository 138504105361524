import { __decorate, __extends } from "tslib";
import { Vue, Component, Prop } from 'vue-property-decorator';
var UserAvatar = (function (_super) {
    __extends(UserAvatar, _super);
    function UserAvatar() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(UserAvatar.prototype, "avatarInitials", {
        get: function () {
            return this.$store.getters.userNick.toUpperCase().slice(0, 2);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserAvatar.prototype, "photoURL", {
        get: function () {
            return this.$store.state.user.photoURL;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserAvatar.prototype, "textFont", {
        get: function () {
            return this.size / 2 + 'px';
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop({ default: 42 })
    ], UserAvatar.prototype, "size", void 0);
    UserAvatar = __decorate([
        Component
    ], UserAvatar);
    return UserAvatar;
}(Vue));
export default UserAvatar;
