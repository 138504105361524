import color from './loggerColors';
function logger(type, message, log) {
    var _a;
    if (type === void 0) {
        type = 'info';
    }
    if (message === void 0) {
        message = '';
    }
    if (log === void 0) {
        log = '';
    }
    var component = (_a = this.$options) === null || _a === void 0 ? void 0 : _a.name;
    var logMessage = component ? "%c " + component + ".vue: " + message : "%c " + message + " ->";
    return console.log(logMessage, getColorStyles(color[type]), log);
}
function getColorStyles(color) {
    return "color: " + color + "; font-weight:bold;";
}
export default logger;
