var tabItems = [
    'Account',
    'Meetings',
    'Notifications',
    'Billing',
    'Usage',
    'Users',
    'Settings'
];
function importTabComponents() {
    return {
        'Account': function () { return import('@/components/user/UserPreferences/Account.vue'); },
        'Meetings': function () { return import('@/components/user/UserPreferences/Meetings.vue'); },
        'Notifications': function () { return import('@/components/user/UserPreferences/Notifications.vue'); },
        'Billing': function () { return import('@/components/user/UserPreferences/Billing.vue'); },
        'Usage': function () { return import('@/components/user/UserPreferences/Usage.vue'); },
        'Users': function () { return import('@/components/user/UserPreferences/Users.vue'); },
        'Settings': function () { return import('@/components/user/UserPreferences/Settings.vue'); }
    };
}
export { tabItems, importTabComponents };
