var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "hero is-bold", class: _vm.style }, [
    _c("div", { staticClass: "hero-head" }, [_vm._t("head")], 2),
    _c("div", { staticClass: "hero-body" }, [_vm._t("default")], 2),
    _c("div", { staticClass: "hero-foot" }, [_vm._t("foot")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }