var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$auth.currentUser && _vm.$store.state.user
    ? _c(
        "VAvatar",
        {
          staticClass: "clickable UserAvatar",
          attrs: { size: _vm.size, color: "primary" }
        },
        [
          _vm.photoURL
            ? _c("img", { attrs: { src: _vm.photoURL } })
            : _c("span", { style: { fontSize: _vm.textFont } }, [
                _vm._v(_vm._s(_vm.avatarInitials))
              ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }