var modal = {
    twilioCard: false
};
var modalMutations = {
    setModalState: function (_a, status) {
        var modal = _a.modal;
        return (modal.twilioCard = status);
    }
};
export { modal, modalMutations };
