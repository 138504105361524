import { __assign, __awaiter, __generator } from "tslib";
import { auth, db } from '@/initFirebase';
import toast from '../../helpers/toastHelpers';
var user = {
    displayName: '',
    email: '',
    phoneNumber: '',
    photoURL: '',
    providerId: '',
    uid: '',
    coverImg: ''
};
var userCopy = user;
var authActions = {
    loginWithEmail: function (_a, _b) {
        var commit = _a.commit;
        var email = _b.email, password = _b.password;
        return __awaiter(void 0, void 0, void 0, function () {
            var user_1, error_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4, auth.signInWithEmailAndPassword(email, password)];
                    case 1:
                        user_1 = (_c.sent()).user;
                        commit('setUserState', user_1 ? user_1.providerData : null);
                        return [3, 3];
                    case 2:
                        error_1 = _c.sent();
                        commit('logInError', error_1);
                        setTimeout(function () { return commit('logInError', ''); }, 8000);
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    },
    loadUserState: function (_a, user) {
        var dispatch = _a.dispatch, commit = _a.commit;
        return __awaiter(void 0, void 0, void 0, function () {
            var userProfile, userData;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, db.collection('users').doc(user.uid).get()];
                    case 1:
                        userProfile = _b.sent();
                        userData = getUserData();
                        if (localStorage.userData)
                            dispatch('updateUserData');
                        commit('setUserState', __assign(__assign({}, userProfile.data()), userData));
                        return [2];
                }
            });
        });
    },
    updateUserData: function (_a) {
        var commit = _a.commit;
        return __awaiter(void 0, void 0, void 0, function () {
            var userData;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        userData = JSON.parse(localStorage.userData);
                        return [4, db.doc('users/' + auth.currentUser.uid).update(userData)];
                    case 1:
                        _b.sent();
                        return [4, auth.currentUser.updateProfile({ displayName: userData.displayName })];
                    case 2:
                        _b.sent();
                        localStorage.removeItem('userData');
                        commit('setUserState', userData);
                        return [2];
                }
            });
        });
    },
    updateProfile: function (_a, _b) {
        var dispatch = _a.dispatch;
        var displayName = _b.displayName, phoneNumber = _b.phoneNumber, uid = _b.uid;
        return __awaiter(void 0, void 0, void 0, function () {
            var error_2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 4, , 5]);
                        return [4, auth.currentUser.updateProfile({ displayName: displayName })];
                    case 1:
                        _c.sent();
                        return [4, db.doc('users/' + uid).update({ displayName: displayName, phoneNumber: phoneNumber })];
                    case 2:
                        _c.sent();
                        return [4, dispatch('loadUserState', { uid: uid })];
                    case 3:
                        _c.sent();
                        toast.goodMessage('profile');
                        return [2, true];
                    case 4:
                        error_2 = _c.sent();
                        toast.badMessage('update profile');
                        return [2, error_2];
                    case 5: return [2];
                }
            });
        });
    },
    updatePassword: function (_, password) {
        return __awaiter(void 0, void 0, void 0, function () {
            var error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, auth.currentUser.updatePassword(password)];
                    case 1:
                        _a.sent();
                        toast.goodMessage('password');
                        return [2, true];
                    case 2:
                        error_3 = _a.sent();
                        toast.badMessage('update password');
                        return [2, error_3];
                    case 3: return [2];
                }
            });
        });
    }
};
var authMutations = {
    setUserState: function (state, user) { return (state.user = __assign(__assign({}, state.user), user)); },
    logInError: function (state, error) { return (state.error = error); },
    signOut: function (state) {
        auth.signOut();
        state.user = userCopy;
    },
    setAvatar: function (state, photoURL) { return (state.user.photoURL = photoURL); }
};
function getUserData() {
    var _a = auth.currentUser, email = _a.email, displayName = _a.displayName, photoURL = _a.photoURL, uid = _a.uid;
    return { email: email, displayName: displayName, photoURL: photoURL, uid: uid };
}
export { authMutations, authActions, user };
