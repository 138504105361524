var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VCard",
    { staticClass: "UserMenu", attrs: { outlined: "", elevation: "2" } },
    [
      _c(
        "VNavigationDrawer",
        {
          attrs: { permanent: "" },
          scopedSlots: _vm._u([
            {
              key: "prepend",
              fn: function() {
                return [
                  _c(
                    "VListItem",
                    { staticClass: "UserMenu-header" },
                    [
                      _c("UserAvatar", {
                        staticClass: "UserMenu-header-avatar"
                      }),
                      _c(
                        "VListItemContent",
                        [
                          _c(
                            "VListItemTitle",
                            { staticClass: "headline mb-3" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$store.getters.userNick) + " "
                              )
                            ]
                          ),
                          _c(
                            "VListItemSubtitle",
                            { staticClass: "UserMenu-header-email" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$store.getters.userEmail) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("VDivider"),
          _c(
            "VList",
            { attrs: { dense: "" } },
            _vm._l(_vm.menuItems.userSettings, function(setting) {
              return _c(
                "VListItem",
                {
                  key: setting.title,
                  attrs: { link: "" },
                  on: {
                    click: function($event) {
                      _vm.vm[setting.callback](setting.title),
                        _vm.$emit("toggleMenu")
                    }
                  }
                },
                [
                  _c(
                    "VListItemIcon",
                    [_c("VIcon", [_vm._v(_vm._s(setting.icon))])],
                    1
                  ),
                  _c(
                    "VListItemContent",
                    [_c("VListItemTitle", [_vm._v(_vm._s(setting.title))])],
                    1
                  )
                ],
                1
              )
            }),
            1
          ),
          _c("VDivider"),
          _c("p", { staticClass: "UserMenu-footer" }, [
            _vm._v(
              " @2020 Assetly Términos del servicio Condiciones de facturación Política de privacidad "
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }