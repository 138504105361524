var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "not-found" },
    [
      _c("hero", { attrs: { color: "clear" } }, [
        _c("div", { staticClass: "content has-text-centered" }, [
          _c("h1", { staticClass: "title" }, [_vm._v("Oops!!")]),
          _c("h2", { staticClass: "subtitle is-6" }, [
            _vm._v(
              "It looks like the page you are trying to access is no longer available..."
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }