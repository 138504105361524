var userSettings = [
    {
        title: 'Account',
        icon: 'mdi-folder-account',
        callback: 'goToOldAccount'
    },
    {
        title: 'Log out',
        icon: 'mdi-exit-to-app',
        callback: 'signOut'
    }
];
var adminSettings = [];
export { adminSettings, userSettings };
