import { __decorate, __extends } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
var NavButtonsValidate = (function (_super) {
    __extends(NavButtonsValidate, _super);
    function NavButtonsValidate() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(NavButtonsValidate.prototype, "loginBtnValidate", {
        get: function () {
            return !this.$auth.currentUser && this.$route.path !== '/login';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(NavButtonsValidate.prototype, "avatarInitials", {
        get: function () {
            return this.$store.getters.userNick.toUpperCase().slice(0, 2);
        },
        enumerable: false,
        configurable: true
    });
    NavButtonsValidate = __decorate([
        Component
    ], NavButtonsValidate);
    return NavButtonsValidate;
}(Vue));
export default NavButtonsValidate;
