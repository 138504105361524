var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c("b-loading", {
        attrs: { active: _vm.loading, "is-full-page": false }
      }),
      _c(
        "form",
        {
          staticClass: "form",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.signIn($event)
            }
          }
        },
        [
          _c("p", { staticClass: "is-size-7 form-error" }, [
            _vm._v(_vm._s(_vm.$store.state.error))
          ]),
          _c(
            "b-field",
            {
              attrs: {
                label: "Email",
                "label-position": "on-border",
                "custom-class": "has-text-primary"
              }
            },
            [
              _c("b-input", {
                attrs: { type: "email", placeholder: "User email" },
                model: {
                  value: _vm.email,
                  callback: function($$v) {
                    _vm.email = $$v
                  },
                  expression: "email"
                }
              })
            ],
            1
          ),
          _c(
            "b-field",
            {
              attrs: {
                label: "Password",
                "label-position": "on-border",
                "custom-class": "has-text-primary"
              }
            },
            [
              _c("b-input", {
                attrs: { type: "password", placeholder: "User password" },
                model: {
                  value: _vm.password,
                  callback: function($$v) {
                    _vm.password = $$v
                  },
                  expression: "password"
                }
              })
            ],
            1
          ),
          _c("input", {
            staticClass: "button is-primary is-outlined is-fullwidth",
            attrs: { type: "submit", value: "Sign In" }
          })
        ],
        1
      ),
      _c("div", { staticClass: "container has-text-centered is-size-7" }, [
        _c("p", [
          _c("a", { on: { click: _vm.recoverPassword } }, [
            _vm._v("Forgot your password?")
          ])
        ]),
        _c(
          "p",
          [
            _vm._v("Dont have an account? "),
            _c("router-link", { attrs: { to: "/register" } }, [
              _vm._v("Register here!")
            ])
          ],
          1
        )
      ]),
      _c(
        "b-button",
        {
          attrs: { expanded: "", outlined: "" },
          on: { click: _vm.autodeskLogin }
        },
        [
          _c("div", { staticClass: "is-inline-flex" }, [
            _c("span", [_vm._v("Login with ")]),
            _c("img", {
              staticClass: "adeskLogo",
              attrs: {
                src:
                  "https://github.com/Autodesk-Forge/learn.forge.viewhubmodels/raw/master/img/autodesk_text.png"
              }
            })
          ])
        ]
      ),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content has-text-centered is-size-7" }, [
      _c("span", [
        _vm._v("You may need to provision your BIM 360 account for BIMMeet. "),
        _c(
          "a",
          {
            attrs: {
              href:
                "https://forge.autodesk.com/blog/bim-360-docs-provisioning-forge-apps"
            }
          },
          [_vm._v("Learn more")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }