var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "intro-page" },
    [
      _c(
        "hero",
        { attrs: { color: "clear", nav: !_vm.$route.meta.hideNavbar } },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c("div", { staticClass: "column intro-text" }, [
                _c(
                  "h1",
                  {
                    staticClass:
                      "is-size-3-fullhd is-size-4-mobile is-size-4-tablet has-text-centered-mobile has-text-weight-semibold"
                  },
                  [_vm._v("Cloud meetings for BIM Projects")]
                ),
                _c(
                  "h2",
                  {
                    staticClass:
                      "is-size-5-fullhd is-size-5-desktop is-size-6-mobile has-text-centered-mobile"
                  },
                  [
                    _vm._v(
                      "Hold coordination meetings within your model published in BIM360. In this way you can have a greater immersion to improve the coordination of your project. Generate incidents in the meeting itself and follow its traceability in BIM360."
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "meeting-buttons is-flex has-text-centered-tablet"
                  },
                  [
                    _c(
                      "b-field",
                      { attrs: { grouped: "", "group-multiline": "" } },
                      [
                        _c(
                          "p",
                          { staticClass: "control" },
                          [
                            _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  label: _vm.createButtonTooltipLabel(),
                                  active:
                                    !_vm.$auth.currentUser ||
                                    !_vm.$auth.currentUser.emailVerified,
                                  type: "is-danger",
                                  size: "is-small",
                                  multilined: ""
                                }
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      "icon-left": "video",
                                      type: "is-primary",
                                      loading: _vm.creatingMeet,
                                      disabled:
                                        !_vm.$auth.currentUser ||
                                        !_vm.$auth.currentUser.emailVerified
                                    },
                                    on: { click: _vm.createNewMeet }
                                  },
                                  [
                                    _vm._v("Start a BIM"),
                                    _c(
                                      "span",
                                      { staticClass: "has-text-weight-bold" },
                                      [_vm._v("Meet")]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("p", { staticClass: "control is-flex" }, [
                          _c(
                            "span",
                            {
                              staticClass: "has-text-centered",
                              staticStyle: { "align-self": "center" }
                            },
                            [_vm._v("or")]
                          )
                        ]),
                        _c("b-input", {
                          staticClass: "input_code",
                          attrs: {
                            placeholder: "Enter a code",
                            icon: "keyboard"
                          },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.$router.push("/meet/" + _vm.meetCode)
                            }
                          },
                          model: {
                            value: _vm.meetCode,
                            callback: function($$v) {
                              _vm.meetCode = $$v
                            },
                            expression: "meetCode"
                          }
                        }),
                        _c(
                          "p",
                          { staticClass: "control" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  type: _vm.meetCode ? "is-primary" : "is-text",
                                  disabled: !_vm.meetCode
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$router.push(
                                      "/meet/" + _vm.meetCode
                                    )
                                  }
                                }
                              },
                              [_vm._v("Join")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("card-video-bim-meet")
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }