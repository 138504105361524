import { __assign, __awaiter, __generator } from "tslib";
import { connect } from 'twilio-video';
import { auth, functions } from '../../initFirebase';
var twilioState = {
    id: '',
    token: '',
    options: {
        audio: '',
        video: '',
        audioEnabled: '',
        videoEnabled: ''
    },
    height: 180,
    width: 300,
    room: null,
    participants: []
};
var twilioMutations = {
    setTwilioToken: function (state, token) { return (state.twilio = __assign(__assign({}, state.twilio), { token: token })); },
    setTwilioBaseState: function (state, baseState) { return (state.twilio = __assign(__assign({}, state.twilio), baseState)); },
    setTwilioRoom: function (state, room) { return (state.twilio = __assign(__assign({}, state.twilio), { room: room })); },
    setTwilioParticipants: function (state, participants) { return (state.twilio = __assign(__assign({}, state.twilio), { participants: participants })); }
};
var twilioActions = {
    getTwilioToken: function (_a, roomId) {
        var commit = _a.commit, dispatch = _a.dispatch;
        return __awaiter(this, void 0, void 0, function () {
            var data, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4, functions.httpsCallable('twilioToken')({
                                room: roomId,
                                user: auth.currentUser.email
                            })];
                    case 1:
                        data = (_b.sent()).data;
                        commit('setTwilioToken', data);
                        dispatch('connectRoom', data);
                        return [3, 3];
                    case 2:
                        error_1 = _b.sent();
                        console.log('getTwilioToken -> error', error_1);
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    },
    connectRoom: function (_a, token) {
        var commit = _a.commit, state = _a.state;
        return __awaiter(this, void 0, void 0, function () {
            var _b, id, options, height, width, twilioroom;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = state.twilio, id = _b.id, options = _b.options, height = _b.height, width = _b.width;
                        console.log('connectRoom -> token', token);
                        return [4, connect(token, {
                                name: id,
                                audio: (options === null || options === void 0 ? void 0 : options.audio) && { deviceId: options.audio.deviceId },
                                video: (options === null || options === void 0 ? void 0 : options.video) && {
                                    width: width,
                                    height: height,
                                    deviceId: options.video.deviceId
                                }
                            })];
                    case 1:
                        twilioroom = _c.sent();
                        commit('setTwilioRoom', twilioroom);
                        return [2];
                }
            });
        });
    }
};
export { twilioState as twilio, twilioActions, twilioMutations };
