var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VAppBar",
    { staticClass: "NavBar", attrs: { app: "", color: "white", dark: "" } },
    [
      _c("RouterLink", { staticClass: "NavBar-logo", attrs: { to: "/" } }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: require("@/assets/images/bim-meet-icon.png") }
        }),
        _c("span", { staticClass: "has-text-info has-text-weight-medium" }, [
          _vm._v("BIM")
        ]),
        _c("span", { staticClass: "has-text-primary has-text-weight-bold" }, [
          _vm._v("Meet")
        ])
      ]),
      _c("VSpacer"),
      !_vm.$auth.currentUser
        ? _c(
            "VBtn",
            {
              attrs: { color: "blue-grey", outlined: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push("register")
                }
              }
            },
            [
              _c("VIcon", { attrs: { left: "", size: "large" } }, [
                _vm._v("mdi-account-edit")
              ]),
              _vm._v(" Sign up ")
            ],
            1
          )
        : _vm._e(),
      _vm.loginBtnValidate
        ? _c(
            "VBtn",
            {
              attrs: { color: "primary", depressed: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push("login")
                }
              }
            },
            [
              _c("VIcon", { attrs: { left: "", size: "large" } }, [
                _vm._v("mdi-account-arrow-right")
              ]),
              _vm._v(" Sign in ")
            ],
            1
          )
        : _vm._e(),
      _vm.$store.getters.userRole === "admin" && _vm.$route.hash !== "Admin"
        ? _c(
            "VBtn",
            {
              attrs: { depressed: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/admin-panel")
                }
              }
            },
            [
              _c("VIcon", { attrs: { left: "", size: "large" } }, [
                _vm._v("mdi-security")
              ]),
              _vm._v(" Admin Panel ")
            ],
            1
          )
        : _vm._e(),
      _c("UserAvatar", {
        nativeOn: {
          click: function($event) {
            return _vm.$emit("toggleMenu")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }