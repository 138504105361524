import { SnackbarProgrammatic as snack, ToastProgrammatic as toast } from 'buefy';
var badMessage = function (text) {
    return toast.open({
        message: "Something bad happened and couldn't " + text + ".",
        type: 'is-danger',
        duration: 5000
    });
};
var goodMessage = function (text, customMsg) {
    if (customMsg === void 0) {
        customMsg = null;
    }
    return toast.open({
        message: customMsg ? customMsg : "Succesfully updated " + text + ".",
        type: 'is-success',
        duration: 5000
    });
};
var Snack = {
    meetCreated: function (meetUrl) {
        return snack.open({
            duration: 5000,
            message: "Meeting created! <a href=\"" + meetUrl + "\" class=\"Snack-meet\" target=\"_blank\">\n        open on calendar!\n      </a>",
            type: 'is-danger',
            actionText: 'Close'
        });
    }
};
export { Snack };
export default {
    badMessage: badMessage,
    goodMessage: goodMessage
};
