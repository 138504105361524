var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VApp",
    { staticClass: "App" },
    [
      _c(
        "Transition",
        { staticClass: "absolute", attrs: { name: "slide-top" } },
        [
          !_vm.$route.meta.hideNavbar
            ? _c("MainNav", {
                on: {
                  toggleMenu: function($event) {
                    _vm.userMenu = !_vm.userMenu
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "Transition",
        { attrs: { name: "fade" } },
        [
          _vm.userMenu
            ? _c("UserMenu", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.userMenu,
                    expression: "userMenu"
                  },
                  {
                    name: "on-clickaway",
                    rawName: "v-on-clickaway",
                    value: _vm.toggleUserMenu,
                    expression: "toggleUserMenu"
                  }
                ],
                on: {
                  toggleMenu: function($event) {
                    _vm.userMenu = !_vm.userMenu
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "VMain",
        [
          _c(
            "Transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [_c("RouterView")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }