var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "column" }, [
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-header" }, [
        _c(
          "div",
          { staticClass: "card-header-icon" },
          [_c("BIcon", { attrs: { icon: "question" } })],
          1
        ),
        _vm._m(0)
      ]),
      _vm._m(1)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header-title" }, [
      _vm._v(" What is a "),
      _c(
        "span",
        { staticClass: "has-text-info has-text-weight-medium spacing" },
        [_vm._v("BIM")]
      ),
      _c("span", { staticClass: "has-text-primary has-text-weight-bold" }, [
        _vm._v("Meet ?")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("figure", { staticClass: "is16by9" }, [
      _c("iframe", {
        attrs: { src: "https://player.vimeo.com/video/429976526" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }