import Vue from 'vue';
import App from './App.vue';
import logger from './plugins/logger';
import './registerServiceWorker';
import './initFirebase';
import './initFontawesome';
import router from './router';
import store from './store';
import apolloProvider from './apolloProvider';
import Hero from '@/components/bulma/Hero.vue';
import DatePicker from 'v-calendar/lib/components/date-picker.umd.min.js';
import Buefy from 'buefy';
import VueApollo from 'vue-apollo';
import VueJsonp from 'vue-jsonp';
import VueGAPI from 'vue-gapi';
import VueChatScroll from 'vue-chat-scroll';
import Avatar from 'vue-avatar';
import VueCompositionApi from '@vue/composition-api';
import VueFormulate from '@braid/vue-formulate';
import 'vue-good-table/dist/vue-good-table.css';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import vuetify from './plugins/vuetify';
Vue.use(Vuetify);
Vue.config.devtools = process.env.NODE_ENV === 'development';
Vue.use(VueFormulate);
Vue.component('Avatar', Avatar);
Vue.use(VueJsonp);
Vue.component('Hero', Hero);
Vue.use(VueApollo);
Vue.use(VueChatScroll);
Vue.use(VueCompositionApi);
Vue.use(Buefy, {
    defaultIconComponent: 'vue-fontawesome',
    defaultIconPack: 'fas'
});
Vue.component('DatePicker', DatePicker);
Vue.config.productionTip = false;
Vue.prototype.$log = logger;
var app = undefined;
var calendarConfig = JSON.parse(process.env.VUE_APP_GOOGLE_CALENDAR_CONFIG);
Vue.use(VueGAPI, calendarConfig);
Vue.prototype.$auth.onAuthStateChanged(function (user) {
    if (!app) {
        app = new Vue({
            router: router,
            store: store,
            apolloProvider: apolloProvider,
            vuetify: vuetify,
            render: function (h) { return h(App); }
        }).$mount('#app');
        user ? store.dispatch('loadUserState', user) : store.commit('setUserState', null);
    }
    else {
        console.log('%c⧭', 'color: #00a3cc', 'app is not null... reloading page');
        router.go(0);
    }
});
