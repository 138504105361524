var viewer = {
    isToolBarCreated: false
};
var markupMutations = {
    setMarkupExtension: function (_a) {
        var viewer = _a.viewer;
        return (viewer.isToolBarCreated = true);
    }
};
export { viewer, markupMutations };
